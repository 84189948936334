<template>
  <div class="list_stock">
   <stock-list></stock-list>
  </div>
</template>

<script>
// @ is an alias to /src
// import PickOrders from '@/components/pickorders/PickOrders.vue'
import StockList from '../components/stock/StockList.vue'


export default {
  name: 'Stock',
  components: {
    StockList
  }
}
</script>
