<template>
  <div>
    <b-container align="left" fluid>
      <search-form v-on:on-search="doSearch" />
      <b-row>
        <b-col lg="3">
          <b-form>
            <b-input-group class="mb-2">
              <b-input-group-prepend>
                <b-button disabled variant="outline-secondary">{{ totalRows }} hits</b-button>
              </b-input-group-prepend>
              <b-form-input class="filterfield" id="filter-input" v-model="filter" type="search"
                placeholder="Skriv her for at søge i resultat"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Nulstil</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </b-col>
      </b-row>


      <b-table sticky-header="1000px" striped hover class="my-4" :items="items" :fields="fields"
        :current-page="currentPage" :per-page="perPage" responsive="true" small :key="updateKey"
        :tbody-tr-class="rowClass" head-variant="light">
        <template #cell(imageUrl)="row">
          <b-img-lazy thumbnail fluid blank-src blank-height="200px" blank-width="200px" :src="row.item.imageUrl">
          </b-img-lazy>
        </template>

        <template #cell(sampak)="data">
          <span v-if="data.item.rowType === 'parent'">Sampak</span>
        </template>

        <template #cell(actions)="data">
          <b-button-group style="float: right">

            <!-- Request move from secondary location -->
            <b-button v-if="data.item.btncolor === 'warning'"
              :variant="data.item.disabled ? 'outline-warning' : 'warning'" @click="moveStock(data, 'move-perform')"
              :disabled="!data.item.selected || readMode || data.item.disabled">
              <span v-if="data.item.btncolor === 'warning' && data.item.isPicked !== true">Der skal flyttes {{
                data.item.quantity }}
                stk.</span>
              <span v-else>Flyttet</span>
            </b-button>

          </b-button-group>
        </template>


        <template #cell(options)="data">
          <div v-if="data.item.options">
            <b-form-select v-model="data.item.selected" :options="data.item.options"
              v-on:change="getSelectedItem"></b-form-select>
            <div class="mt-3" v-if="data.item.selected">
              <!-- Valgt: <strong>{{ data.item.selected.substr(0, data.item.selected.indexOf("#")) }}</strong> -->
              <div>Valgt: <strong>{{ data.item.selected.split("#")[0] }}</strong></div>
            </div>
            <div class="mt-3" v-if="data.item.selected">
              <b-form-group id="quantity-to-move" label="Antal flyttes" lable-for="quantity-to-move">
                <b-form-input id="quantity-to-move" v-model="quantityToMove" placeholder="Angiv antal"></b-form-input>
              </b-form-group>
            </div>
          </div>
        </template>

        <template #cell(locations)="data">
          <span v-if="data.item.location_list[0].quantity !== ''">{{ data.item.location_list[0].name }}
            {{ data.item.location_list[0].quantity }} stk.</span>
          <span v-else>{{ data.item.location_list[0].name }}</span>
        </template>
      </b-table>

      <b-spinner v-if="isLoading" style="position: fixed; left: 50%; top: 50%; width: 4rem; height: 4rem"></b-spinner>
    </b-container>
  </div>
</template>

<script>
import SearchForm from "./SearchForm.vue";
import apis from "../../apis/Apis";
import cookies from "js-cookie";

export default {
  components: {
    SearchForm,
  },

  data() {
    return {
      fields: [
        { key: "productNumber", label: "Produkt nr." },
        { key: "title", label: "Titel" },
        { key: "imageUrl", label: "Foto" },
        { key: "sampak", label: "Sampak" },
        { key: "actions", label: "" },
        { key: "options", label: "Sekundære Lokation" },
        { key: "locations", label: "Primær lokation" },
      ],
      finishForm: {
        isPartialDelivery: null,
        commentsPartialDelivery: "",
        packedDate: null,
        numberOfPackages: null,
        numberOfPallets: null,
        numberOfScantainers: null,
      },
      selectedLocation: "",
      domSessionId: "",
      // fullname: "",
      id: "",
      updateKey: 0,
      status: "",
      pickingStatus: "started",
      pickingStatusLang: "",
      orderNumber: "",
      isPickingAllowed: false,
      loation: [],
      shipTo: {
        shipToName: "",
        ShipToAttentionName: "",
        ShipToAddress1: "",
        ShipToPostalCode: "",
        ShipToCity: "",
        ShipToCountryCode: "",
        ShipToCountry: "",
      },
      giftWrapping: false,
      nameOnLabels: false,
      includeGiftCards: false,
      comments: "",
      items: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 1000,
      selectedRow: {},

      readMode: false,
      isLoading: null,
      //hidewhen
      startButton: true,
      pauseButton: true,
      finishButton: true,

      totalOrderlines: 0,
      orderLinesPicked: 0,
      missingProductNumbers: 0,

      filter: null,
      filterOn: [],
      queryParam: {},
      productOptionsList: [],
      quantityToMove: 0,

    };
  },
  mounted: function () {
    this.domSessionId = cookies.get("DomSessionId");
    this.getPendingMoveRequests("/pending-move");
  },

  methods: {
    getSelectedItem: function (args) {
      this.quantityToMove = args.split("#")[1]
    },
    // load the picking list
    getPendingMoveRequests: async function (queryString) {

      this.isLoading = true;
      try {
        const response = await apis.loadStock(queryString, this.domSessionId);

        console.log("getPendingMoveRequests data", response.data);

        if (response.data.totalOrderlines == 0) {
                    this.$bvModal.msgBoxOk("Der er ingen flytteanmodninger.  ", {
            title: "Flytte anmodninger",
          });
          this.isLoading = false;
          return;
        }

        this.updateTable(response.data.products);
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },

    doSearch(queryString) {
      this.getPendingMoveRequests(queryString);
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.isPicked === true) return "table-success";
      if (item.isMissingProduct === true) return "table-danger";
    },
    forceUpdate() {
      this.updateKey += 1;
    },

    // picking a product data i row from table. picStatus is string from @moveStock(data, "string")
    moveStock: function (data, pickStatus) {

      if (this.pickingStatus !== "started") {
        this.$bvModal.msgBoxOk(
          "Ordren er ikke startet. Start ordren før du begynder at plukke varer",
          { title: "Fejl" }
        );
        return;
      }

      if (this.quantityToMove == 0) {
        this.$bvModal.msgBoxOk(
          "Antal flyttes skal være større end 0",
          { title: "Fejl" }
        );
        return;
      }
      console.log(data, pickStatus)

      let payload = {};
      payload.location = data.item.selected.substr(0, data.item.selected.indexOf("#"));
      payload.defaultLocation = data.item.defaultLocation;
      payload.productNumber = data.item.productNumber;
      payload.defaultLocQuantity = data.item.defaultLocQuantity;
      // eslint-disable-next-line no-debugger
      debugger
      // payload.quantity = data.item.selected.substr(data.item.selected.indexOf("#") + 1);
      payload.orderNumber = data.item.orderNumber
      payload.quantityToMove = this.quantityToMove

      this.updateStock(data, payload, this.domSessionId, pickStatus);
    },

    updateStock: async function (data, payload, domSessionId, queryString) {
      this.isLoading = true;
      let qString = "";

      if (queryString === "move-perform") {
        qString = "/" + queryString;
      }

      try {
        const response = await apis.updateStock(payload, domSessionId, qString);
        // eslint-disable-next-line no-debugger
        debugger
        if (response.data.status === "success") {
          if (queryString === "move-perform") {
            this.items[data.index].disabled = true;
          }
        } else if (response.data.response === "error") {

          this.$bvModal.msgBoxOk(response.data.errormsg, {
            title: "Fejl i plukning " + response.data.errorcode,
          });

          // alert("failed");
        }

        this.isLoading = false;
        this.getPendingMoveRequests("/pending-move");
        this.forceUpdate();
      } catch (error) {
        console.log(error);
      }
    },
    //end picking a product

    //paint the table
    updateTable: function (items) {

      // console.log("updateTable items", this.items);
      // console.log("updateTable totalOrderlines", this.totalOrderlines);

      // this.items.length = 0;
      // if (this.totalOrderlines == 0) {
      //   console.log("no items");

      //   this.$bvModal.msgBoxOk("Der er ingen flytteanmodninger.  ", {
      //     title: "Flytte anmodninger",
      //   });
      // } else {
        // this.totalRows = items.length;
        items.forEach(this.evalOrderLine);
      // }


    },

    evalOrderLine: function (orderline) {
      let thisLocations = null;
      let thisDefaultLocation = null;
      let thisDefaultQuantity = 0;
      let btncolor = "warning";
      let thisDisabled = false;
      let options = [];
      let quantity = 0;
      let thisOrderNumber = "";

      if (orderline.Locations.length !== 0) {
        thisLocations = orderline.Locations;
        thisDefaultLocation = orderline.Locations[0].name;
        thisDefaultQuantity = orderline.Locations[0].quantity;
      }

      thisOrderNumber = orderline.ordernumber
      quantity = Math.abs(orderline.quantity);

      //make a options list of locations with stock
      let elm = { value: null, text: "Vælg en sekundær lokation" };
      options.push(elm);
      // why n = 2 ?  Not to include the primary location where the items is missing in the list 
      for (var n = 1; n < thisLocations.length; n++) {
        const number = parseInt(thisLocations[n].quantity);
        if (!isNaN(number)) {
          if (number > 0) {
            elm = {
              value: thisLocations[n].name + "#" + thisLocations[n].quantity,
              text: thisLocations[n].name + " " + thisLocations[n].quantity,
            };
            options.push(elm);
          }
        }
      }

      // console.log("evalOrderLine options ", options)

      let rowLine = {
        productNumber: orderline.productNumber,
        title: orderline.title,
        quantity: quantity,
        imageUrl: orderline.imageUrl,
        location_list: thisLocations,
        options: options,
        selected: null,
        defaultLocation: thisDefaultLocation,
        defaultLocQuantity: thisDefaultQuantity,
        // isPicked: thisPickingStatus,
        // isMissingProduct: thisMissingProduct,
        // pickOn: orderline.pickOn,
        rowType: orderline.rowType,
        btncolor: btncolor,
        disabled: thisDisabled,
        orderNumber: thisOrderNumber,
      };

      if (orderline.isPickable !== undefined) {
        rowLine.isPickable = orderline.isPickable;
      }

      // console.log("evalOrderLine ", rowLine.productNumber + " " + rowLine.btncolor + " defaultLocQuantity " + rowLine.defaultLocQuantity)

      this.items.push(rowLine);
      rowLine = null;
    },



    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      return valid;
    },
  }

};
</script>

<style scoped>
::v-deep .sr-only {
  display: none !important;
}
</style>
