<template>
  <div class="my-3">
    <!-- <b-container class="my-3" align="left" fluid> -->
    <b-button variant="outline-dark" v-b-toggle:collapse-1>
      <span class="when-open">Skjul </span>
      <span class="when-closed">Vis </span>
      søgning
    </b-button>
    <b-button @click="pendingStockMove" variant="outline-primary">Flytte anmodninger</b-button>
    <b-collapse id="collapse-1" visible>
      <b-form ref="form" @submit.prevent="handleSubmit" @reset="onReset">
        
        <b-row>
          <b-col class="my-3" lg="3">
            <b-form-group label="Produkt nr.:" label-for="product-number-input"
              invalid-feedback="Product number is required" label-class="font-weight-bold">
              <b-form-input placeholder="Produkt nr." v-model="productNumber"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button-group>
          <b-button class="my-3" type="submit" variant="success">Søg</b-button>
          <b-button class="my-3" type="reset" variant="secondary">Nulstil</b-button>
        </b-button-group>
      </b-form>
    </b-collapse>
    <!-- </b-container> -->
  </div>
</template>

<script>
import cookies from "js-cookie";

export default {
  emits: ["on-search"],
  data() {
    return {
      domSessionId: "",
      fullname: "",
      username: "",
      queryParam: {},
      productNumber: "",
    };
  },
  mounted() {

    this.domSessionId = cookies.get("DomSessionId");
  },
  methods: {
    pendingStockMove: function () {

      // this.queryParam.get = "pending-move";
      this.queryParam = "/pending-move"
      // this.setQueryParams()
      // here we call parent components doSearch via this slot definition  <search-dialog v-on:on-search="doSearch" />
      // this.$emit("on-search", this.createQueryString(this.queryParam));
      this.$emit("on-search", this.queryParam);
      this.queryParam = {};
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.companynameState = valid;
      return valid;
    },
    handleSubmit() {
      //   Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      //   Hide the modal manually
      this.onSearch();
    },
    onSearch: function () {

      this.setQueryParams()
      // here we call parent components doSearch via this slot definition  <search-dialog v-on:on-search="doSearch" />
      this.$emit("on-search", this.createQueryString(this.queryParam));
      this.queryParam = {};
    },
    onReset: function () {
      // eslint-disable-next-line no-debugger
      this.productNumber = null;
    },
    setQueryParams: function () {

      this.queryParam.productNumber = this.productNumber;
    },
    createQueryString: function (queryParam) {

      console.log("doSearch");
      let queryString = "?";
      let clean = false;
      const keys = Object.keys(queryParam);
      keys.forEach((key) => {
        // console.log(`${key}: ${queryParam[key]}`);
        if (!(queryParam[key] === "" || queryParam[key] == null)) {
          // queryString += "&" + key + "=" + queryParam[key];
          queryString += key + "=" + queryParam[key] + "&";
          clean = true;
        }
      });

      if (clean) {
        queryString = queryString.substring(0, queryString.length - 1);
      }
      return queryString;
    },
  },
};
</script>

<style scoped>
.collapsed>.when-open,
.not-collapsed>.when-closed {
  display: none;
}

>>>.custom-radio {
  float: left;
  margin-right: 8px;
}
</style>